import React from "react";

const ContactInformation = () =>
  <section>
    <ul className="contact">
      <li>
        <h3>Address</h3>
        <span>
          329 Spencer Wing
          <br />
          Bedford Square, Bedfordview
          <br />
          2007
        </span>
      </li>
      <li>
        <h3>Email</h3>
        <a href="/#">nxn@nxnanylytics..co.za</a>
      </li>
      <li>
        <h3>Phone</h3>
        <span>+27 (0) 71 172 6237</span>
      </li>
    </ul>
  </section>

export default ContactInformation;
