import React from 'react';

import researchImage from '../../images/research-mat.png';
import advisoryImage from '../../images/advisory.png';
import governanceImage from '../../images/governance.png';

import ServiceOffering from './ServiceOffering';

const ServiceOfferings = () => {
  const SERVICEOFFERINGS = [
    {
      href: '/#',
      image: researchImage,
      heading: 'Research',
      description: 'NXN Analytics places emphasis on technical expertise and sectoral specialisation. Depending on the client’s business area, we draw from a wide range of well-established experts within our partner organisations and a roster of affiliated experts. The preferred model is to conduct a combination of desktop and field research to cross-reference and test recommendations.',
      to: {
        href: '/generic',
        label: 'Learn more',
      },
    },
    {
      href: '/#',
      image: advisoryImage,
      heading: 'Advisory',
      description: 'The focus of Advisory Services is on providing recommendations that deliver practical, implementable solutions. To achieve this, NXN Analytics goes beyond understanding the client’s market positioning, to doing a competitor analysis. This is used to benchmark, identify best practice and recommend new practices that will offer unique offerings to the market.',
      to: {
        href: '/generic',
        label: 'Learn more',
      },
    },
    {
      href: '/#',
      image: governanceImage,
      heading: 'Corporate Governance',
      description: 'While there are laws and codes that inform best practice in corporate governance, the uniqueness of each company requires tailored services. NXN Analytics goes beyond facilitation and training to understand the business model and elicit ideas on how governance structures can position the company to achieve its vision and mission.',
      to: {
        href: '/generic',
        label: 'Learn more',
      },
    },
  ];

  const [serviceOfferings] = React.useState(SERVICEOFFERINGS);

  return (
    <section id="three" className="wrapper style2 spotlights">
       {serviceOfferings.map(serviceOffering => <ServiceOffering key={serviceOffering.heading} {...serviceOffering} />)}
    </section>
  );
}

export default ServiceOfferings;
