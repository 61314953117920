import React from 'react';

import CapabilityList from './CapabilityList';

const Capabilities = () =>
  <section id="two" className="wrapper style3 fade-up">
    <div className="inner">
      <h2>WHAT WE DO - SERVICE OFFERING</h2>
      <div className="features">
        <CapabilityList />
      </div>
    </div>
  </section>

export default Capabilities;
