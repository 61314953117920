import React from 'react';

import AboutUsList from './AboutUsList';

const AboutUs = () =>
  <section id="one" className="wrapper style3 fade-up">
    <div className="inner">
      <div className="features">
        <AboutUsList />
      </div>
    </div>
  </section>

export default AboutUs;
