import React from 'react';

import aboutImage from '../../images/about.jpg';
import visionImage from '../../images/vision.jpg';
import missionImage from '../../images/mission.jpg';

import Feature from './Feature';

const Features = () => {
  const FEATURES = [
    {
      href: '/#',
      image: aboutImage,
      heading: 'About',
      description: 'NXN Analytics is a female owned consulting firm. Established in 2018,the company’s client base has grown to include private companies and trusts,multilateral institutions, development agencies and government departments. The company has a solid network of partners with specialisations in Mining, Finance, Pharmaceuticals,  Environmental, Social and Governance (ESG) standards in public and private institutions, and Political Risk Analysis. Partnerships are formed with individuals and firms whose experts have a minimum of 10 years in their respective fields.',
      to: {
        href: '/generic',
        label: 'Learn more',
      },
    },
/*     {
      href: '/#',
      image: visionImage,
      heading: 'Vision',
      description: 'To be the consulting firm of choice on research, advisory and corporate governance services in Africa.',
      to: {
        href: '/generic',
        label: 'Learn more',
      },
    },
    {
      href: '/#',
      image: missionImage,
      heading: 'Mission',
      description: 'To provide bespoke consulting services that solve puzzles for clients.',
      to: {
        href: '/generic',
        label: 'Learn more',
      },
    }, */
  ];

  const [features] = React.useState(FEATURES);

  return (
    <section id="one" className="wrapper style2 spotlights">
       {features.map(feature => <Feature key={feature.heading} {...feature} />)}
    </section>
  );
}

export default Features;
