import React from 'react';
import Scroll from '../common/Scroll';

import NxnLogo from '../../images/nxnlogo.png';

const Introduction = () =>
  <section id="intro" className="wrapper style1 fullscreen fade-up">
    <div className="inner">
    <a className="image" alt="image">
      <img src={NxnLogo} alt="" data-position="center center" />
    </a>
      <ul className="actions">
        <li>
          <Scroll type="id" element="one">
            <a href="#one" className="button">
              Let's Explore
            </a>
          </Scroll>
        </li>
      </ul>
    </div>
  </section>


export default Introduction;
