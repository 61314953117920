import React from 'react';

import AboutUsItem from './AboutUsItem';

const AboutUsList = () => {
  const ABOUTUSITEMS = [
    {
      heading: 'Vision',
      description: 'To be the consulting firm of choice on research, advisory and corporate governance services in Africa.',
      iconClass: 'fa-code',
    },
    {
      heading: 'Mission',
      description: 'To provide bespoke consulting services that solve puzzles for client.',
      iconClass: 'fa-gavel',
    },
  ]

  const [aboutUsItems] = React.useState(ABOUTUSITEMS);

  return (
    <>
      {aboutUsItems.map((aboutUsItem) => <AboutUsItem key={aboutUsItem.heading} {...aboutUsItem} />)}
    </>
  )
}

export default AboutUsList;
